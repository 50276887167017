<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">Процедурная</h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
        "
      >
        <!-- <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Заказы"
          name="Заказы.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete"
            >Excel</el-button
          >
        </export-excel> -->
        <crm-column-settings
          :columns="columns"
          :modelName="'doctorCabinet'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.user_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input
              size="mini"
              v-model="filterForm.patient_id"
              :placeholder="columns.patient_id.title"
              clearable
            ></el-input>
            <!-- <el-select filterable clearable :placeholder="columns.patient_id.title" size="mini"
                      v-model="filterForm.patient_id">
                      <el-option v-for="item in list" :key="'patient'+item.patient.id" :label="item.patient.first_name + ' ' + item.patient.surname"
                          :value="item.patient.id"></el-option>
            </el-select>-->
          </th>

          <th v-if="columns.user_id.show">
            <el-input
              size="mini"
              v-model="filterForm.user_id"
              disabled
              :placeholder="columns.user_id.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.service_id.show">
            <el-input
              size="mini"
              v-model="filterForm.service_id"
              disabled
              :placeholder="columns.service_id.title"
              clearable
            ></el-input>
          </th>

          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            ></el-date-picker>
          </th>

          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            ></el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>
      <transition-group name="flip-list" tag="tbody">
        <tr v-for="item in list" :key="item.id">
          <td v-if="columns.id.show">{{ item.id }}</td>
          <td v-if="columns.patient_id.show">
            {{
              item.patient
                ? item.patient.surname + " " + item.patient.first_name
                : ""
            }}
          </td>
          <td v-if="columns.user_id.show">
            {{ item.user ? item.user.name : "" }}
          </td>
          <td v-if="columns.service_id.show">
            {{ item.order ? item.order.order_services[0].name : " - " }}
          </td>
          <td v-if="columns.created_at.show">
            {{ item.created_at }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ item.updated_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <div class="za_btn">
              <el-button
                @click="show(item.id)"
                type="primary"
                icon="el-icon-view"
                >{{ $t("message.show") }}</el-button
              >
            </div>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      size="95%"
      :visible.sync="drawer.show.status"
      :ref="drawer.show.name"
      @opened="drawerOpened(drawer.show.component)"
      @closed="drawerClosed(drawer.show.component)"
    >
      <crm-show
          :selected="selectedModel"
        :ref="drawer.show.component"
        :drawer-name="drawer.show.name"
        @c-close="closeDrawer"
      >
      </crm-show>
    </el-drawer>
    <!-- <el-drawer
      title="Изменить тег"
      :visible.sync="drawerShow"
      size="70%"
      :drawer="drawerShow"
      @open="reopenShow = true"
      @close="reopenShow = false"
      @closed="emptyModel"
    >
      <div>
        <crm-show
          :reloadModel="reopenShow"
          @open="reopenShow = true"
          @c-close="reloadIfChanged('drawerShow')"
          @closed="emptyModel"
        ></crm-show>
      </div>
    </el-drawer> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmShow from "./components/crm-show";
import lists from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [lists,drawer],
  components: {
    CrmShow,
  },
  data() {
    return {
      drawerCreateCoculation: false,
      filterForm: {},
      drawerShow: false,
      reopenShow: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      selectedModel: {},
      loadingData: false,
      drawer: {
       
        show: {
          name: "show",
          status: false,
          component: "componentDrawerShow",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "patientMedicine/patient_medicine",
      columns: "patientMedicine/columns",
      pagination: "patientMedicine/pagination",
      filter: "patientMedicine/filter",
      sort: "patientMedicine/sort",
    }),
  },
  async created() {},
  async mounted() {
    if (this.list && this.list.length === 0) await this.loadPartnerDoctors();
  },
  methods: {
    ...mapActions({
      // updateList: "patientMedicine/patientList",
      showModel: "patientMedicine/patientShow",
      updateSort: "patientMedicine/updateSort",
      updateFilter: "patientMedicine/updateFilter",
      updateColumn: "patientMedicine/updateColumn",
      updatePagination: "patientMedicine/updatePagination",
      empty: "patientMedicine/empty",
      refreshData: "patientMedicine/refreshData",
      loadPartnerDoctors: "patientMedicine/patientMedicineShow",
    }),
    async fetchData() {
      const query = {
        doctor: "patsents",
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.loadPartnerDoctors(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData();
    },
    async show(model) {
      this.selectedModel = model;
      this.drawer.show.status = true;
      this.$loadingCursor("wait");
      await this.showModel(model)
        .then((res) => {
          this.$loadingCursor("default");
          this.drawerShow = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    emptyModel() {
      // this.$refs["createDrawer"].afterClosed();
      this.empty();
    },
  },
};
</script>
<style lang="scss" scoped>
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;
    a {
      display: block;
      padding: 0.3rem;
      color: #000;
      font-weight: 200 !important;
      font-size: 12px !important;
    }
  }
}
</style>



